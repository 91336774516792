import React from "react";
import "./ClientTestimonial.css"; // Updated CSS file

const testimonials = [
  {
    name: "Jack Davis",
   // title: "CEO of Tech Innovations",
    image: require("../../Assest/Client1.jpg"),
    feedback:
      "Webixgo’s strategic approach to SEO and PPC has significantly enhanced our online presence, driving higher traffic and improved search rankings. Their expertise in digital marketing is truly commendable.",
  },
  {
    name: "Zuri Harris",
    //title: "Marketing Director at GreenWave Solutions",
    image: require("../../Assest/Client2.jpg"),
    feedback:
      "Webixgo completely revamped our website with a modern, user-friendly design that perfectly aligns with our brand identity. Their attention to detail, responsiveness and seamless navigation have significantly improved user experience. The new website not only looks stunning but also functions flawlessly, enhancing customer engagement and satisfaction.",
  },
  {
    name: "Chloe Evans",
   // title: "Founder of Urban Eats",
    image: require("../../Assest/Client3.jpg"),
    feedback:
      "Webixgo’s social media marketing and branding solutions have been instrumental in strengthening our brand identity. Their data-driven strategies have led to increased engagement, visibility and business growth.",
  },
];

const TestimonialCard = ({ name, title, image, feedback }) => (
  <div className="testimonial-card">
    <img
      src={image || "/path-to-fallback-image.webp"}
      alt={`${name}`}
      className="testimonial-image"
      loading="lazy"
    />
    <h3 className="testimonial-name">{name}</h3>
    <p className="testimonial-title">{title}</p>
    <p className="testimonial-feedback">"{feedback}"</p>
  </div>
);

const ClientTestimonials = () => {
  return (
    <div
      className="testimonials-container"
      role="region"
      aria-labelledby="testimonials-title"
    >
      <h2 id="testimonials-title" className="testimonials-title">
        What Our Clients Say About Our Digital Marketing Services
      </h2>
      <p className="testimonials-description">
        At GloryWebs, client satisfaction is our top priority. We are proud of
        the results we’ve achieved for our clients, and their testimonials
        reflect the impact of our digital marketing services. Our commitment to
        delivering exceptional service and measurable success has earned us the
        trust of businesses across various industries. Here’s what some of our
        clients have to say about partnering with us.
      </p>
      <div className="testimonials-grid">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            name={testimonial.name}
            title={testimonial.title}
            image={testimonial.image}
            feedback={testimonial.feedback}
          />
        ))}
      </div>
    </div>
  );
};

export default ClientTestimonials;
