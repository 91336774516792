// SEOcontentwriting.js
import React from 'react';
import './SEOcontentwriting.css';

const SEOcontentwriting = () => {
  return (
    <div>
      {/* Banner Section */}
      <section className="SEOcontentwriting-banner">
        <h1>What Is Content Writing?</h1>
        <p>Engage Your Target Audience and Foster Trust</p>
      </section>

      {/* Introduction Section */}
      <section className="SEOcontentwriting-section">
        <h2>Introduction</h2>
        <div className="SEOcontentwriting-content">
          <p>At Webixgo, we offer professional SEO Content Writing services in Amritsar, designed to enhance your website’s visibility and drive organic traffic. As an experienced SEO content writing company in Amritsar, we focus on creating high-quality, keyword-optimized content that resonates with both search engines and your target audience. Our content writing services include blog posts, articles, product descriptions, landing pages, and more—all crafted with SEO best practices to ensure maximum ranking potential.</p>
        </div>
      </section>

      {/* Comparison Section */}
      <section className="SEOcontentwriting-section">
        <h2>SEO Content Writing vs. SEO Content Marketing</h2>
        <div className="SEOcontentwriting-row">
          <div className="SEOcontentwriting-column">
            <h3>SEO Content Writing</h3>
            <p>Our team of expert content writers in Amritsar conducts in-depth keyword research to find the most relevant and 
        effective keywords for your industry. We focus on crafting engaging, informative and optimized content that improves 
        search rankings and drives targeted traffic. Whether it’s blog posts, website content or landing pages, 
        Webixgo’s SEO content writing services ensure your business gets discovered online and attracts potential customers.</p>
          </div>
          <div className="SEOcontentwriting-column">
            <h3>SEO Content Marketing</h3>
            <p>SEO content marketing is a strategic approach focused on publishing and distributing high-quality, SEO-optimized 
        content across various digital platforms. It helps build brand authority, engage audiences and convert potential 
        customers. By leveraging blogs, guest posts, infographics and social media, Webixgo’s content marketing services 
        ensure long-term digital growth and a strong online presence.</p>
          </div>
        </div>
      </section>

      {/* Conclusion Section */}
      <section className="SEOcontentwriting-section">
        <h2>Conclusion</h2>
        <div className="SEOcontentwriting-content">
          <p>Combined SEO content creation and SEO content marketing efforts work best to provide value to your audience and gain their trust. Ensuring your content aligns with your goals will enhance your online presence.</p>
        </div>
      </section>
    </div>
  );
};

export default SEOcontentwriting;
