import React from "react";
import "./Wordpress.css"; // Ensure the CSS includes styles for the updated layout
import { FaCode, FaMobileAlt, FaSearch, FaCogs,FaRocket, FaBrush, FaClipboardCheck, FaLayerGroup } from "react-icons/fa";
import wordpressImage from "../../../Assest/WP.jpeg"; // Replace with the actual image path

const WordPressBanner = () => {
  return (
    <section className="WordPressBanner-container">
      {/* What Is WordPress Section */}
      <div className="WordPressBanner-content-wrapper">
        <div className="WordPressBanner-content">
          <h1 className="WordPressBanner-title">What Is WordPress?</h1>
          <p className="WordPressBanner-description">
            Drive Results With the Most Popular Content Management System
          </p>
          <p className="WordPressBanner-text">
          WordPress Design is one of the most versatile and cost-effective solutions for businesses looking to build an engaging and professional online presence. At Webixgo, we provide custom WordPress design services that are both visually stunning and user-friendly. As an expert WordPress design company, we specialize in creating responsive, mobile-friendly WordPress websites that reflect your brand’s uniqueness.

Our team focuses on combining creativity with functionality, ensuring your WordPress website is easy to navigate and optimized for performance. We design with SEO in mind, ensuring your site ranks well in search engines while also providing a seamless user experience. Whether you're looking for an e-commerce platform, a portfolio, or a blog, Webixgo can create a WordPress design that suits your needs. We offer full WordPress theme customization, plugin integration, and site maintenance to help you build a scalable and future-proof WordPress website.
          </p>
          <p className="WordPressBanner-text">
            The vision of the original WordPress development team was to create
            a digital space where users can share their story freely. The team
            ensured that users could:
          </p>
          <ul className="WordPressBanner-list">
            <li>
              <FaCode className="WordPressBanner-icon" /> Use the software for free.
            </li>
            <li>
              <FaMobileAlt className="WordPressBanner-icon" /> Extend or modify the
              WordPress code however needed.
            </li>
            <li>
              <FaSearch className="WordPressBanner-icon" /> Use the code for
              commercial purposes without licensing fees.
            </li>
          </ul>
          <p className="WordPressBanner-text">
            So, what is WordPress exactly and what is WordPress used for today?
          </p>
          <p className="WordPressBanner-text">
            WordPress is an open-source software and content management system
            (CMS) that powers:
          </p>
          <ul className="WordPressBanner-list">
            <li>
              <FaCogs className="WordPressBanner-icon" /> Websites
            </li>
            <li>
              <FaCogs className="WordPressBanner-icon" /> Enterprise websites
            </li>
            <li>
              <FaCogs className="WordPressBanner-icon" /> Blogs
            </li>
            <li>
              <FaCogs className="WordPressBanner-icon" /> Applications
            </li>
            <li>
              <FaCogs className="WordPressBanner-icon" /> Complex portals
            </li>
            <li>
              <FaCogs className="WordPressBanner-icon" /> Anything you can imagine
            </li>
          </ul>
        </div>
        <div className="WordPressBanner-image-container">
          <img
            src={wordpressImage}
            alt="WordPress illustration"
            className="WordPressBanner-image"
          />
        </div>
      </div>

      {/* New Section: WordPress Web Design Services */}
      <div className="WordPressServices-section">
        <h2 className="WordPressServices-title">WordPress Web Design Services</h2>
        <p className="WordPressServices-subtitle">
          Attract More Customers With a Top-Performing Website
        </p>
        <div className="WordPressServices-grid">
          {/* Service Cards */}
          <div className="WordPressService-card">
            <h3 className="WordPressService-card-title">
              Professional WordPress Design and Development
            </h3>
            <p className="WordPressService-card-text">
              Our WordPress experts create websites tailored to generate results for your business.
            </p>
          </div>
          <div className="WordPressService-card">
            <h3 className="WordPressService-card-title">WordPress Management</h3>
            <p className="WordPressService-card-text">
              We help manage your WordPress website, keeping it secure and up-to-date.
            </p>
          </div>
          <div className="WordPressService-card">
            <h3 className="WordPressService-card-title">WordPress Maintenance</h3>
            <p className="WordPressService-card-text">
              Regular updates and backups ensure smooth and secure performance.
            </p>
          </div>
          <div className="WordPressService-card">
            <h3 className="WordPressService-card-title">Content Writing</h3>
            <p className="WordPressService-card-text">
              Enhance your website with engaging, SEO-optimized content written by professionals.
            </p>
          </div>
          <div className="WordPressService-card">
            <h3 className="WordPressService-card-title">
              Conversion Rate Optimization
            </h3>
            <p className="WordPressService-card-text">
              Maximize leads and revenue through seamless user experiences.
            </p>
          </div>
          <div className="WordPressService-card">
            <h3 className="WordPressService-card-title">Technical SEO</h3>
            <p className="WordPressService-card-text">
              Optimize your site’s speed, structure, and performance for better rankings.
            </p>
          </div>
        </div>
      </div>
      <section className="WhyHireWordPress-container">
      <div className="WhyHireWordPress-content">
        <h2 className="WhyHireWordPress-title">Why Hire a WordPress Web Design Company</h2>
        <p className="WhyHireWordPress-subtitle">
          Stay Ahead of Your Competition by Entrusting Our Experts
        </p>
        <p className="WhyHireWordPress-text">
          If you want to stand out and be noticed by your target audience, it will take a lot more than standard templates and basic site functionality. Your biggest competitor is probably working with a top WordPress web design agency — and you should be, too.
        </p>
        <div className="WhyHireWordPress-grid">
          <div className="WhyHireWordPress-card">
            <FaRocket className="WhyHireWordPress-icon" />
            <h3 className="WhyHireWordPress-card-title">Maximum Efficiency</h3>
            <p className="WhyHireWordPress-card-text">
              A top WordPress design agency would have years of experience that could not compare to figuring it out on your own. An experienced WordPress web designer would be able to create or redesign your website efficiently and get your site up and running as soon as possible.
            </p>
          </div>
          <div className="WhyHireWordPress-card">
            <FaBrush className="WhyHireWordPress-icon" />
            <h3 className="WhyHireWordPress-card-title">Custom-Fit Design</h3>
            <p className="WhyHireWordPress-card-text">
              WordPress web design services are not simply about creating a website but rather, crafting your brand’s best online representation. A WordPress web design agency would schedule regular consultations with your team to fully understand your project requirements.
            </p>
          </div>
          <div className="WhyHireWordPress-card">
            <FaClipboardCheck className="WhyHireWordPress-icon" />
            <h3 className="WhyHireWordPress-card-title">Worry-Free Management</h3>
            <p className="WhyHireWordPress-card-text">
              A WordPress design company creates a staging site to ensure your WordPress web design meets your expectations. You can then communicate any changes you want to make without risking unwanted edits.
            </p>
          </div>
          <div className="WhyHireWordPress-card">
            <FaLayerGroup className="WhyHireWordPress-icon" />
            <h3 className="WhyHireWordPress-card-title">Multi-Layer Support</h3>
            <p className="WhyHireWordPress-card-text">
              An effective site must have a robust foundation primed for SEO, fast page load speed, and mobile-responsiveness. A WordPress design services agency would handle all these strategies for you.
            </p>
          </div>
        </div>
      </div>
    </section>
    </section>
  );
};

export default WordPressBanner;
