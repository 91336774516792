import React from "react";
import "./InstagramMarketing.css";
import { FaChartBar, FaDatabase, FaGlobe, FaPalette } from "react-icons/fa";
import img1 from "../../../Assest/insta.webp";


const InstagramMarketing = () => {
  return (
    <>

      <div className="instagram-marketing-page">
        {/* Banner Section */}
        <section className="instagram-marketing-page-banner">
          <h1>Instagram Marketing</h1>
          <p>Enhance Your Brand’s Presence with Powerful Instagram Campaigns</p>
        </section>

        {/* Text and Image Section */}
        <section className="instagram-marketing-page-text-image">
          <div className="instagram-text-image-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Engage and Convert Customers on Instagram
            </h2>
            <p>
            Instagram Marketing is a visual-first approach to digital marketing that helps businesses showcase their brand in creative and engaging ways. Webixgo specializes in Instagram marketing strategies that allow businesses to connect with their audience through captivating visuals, stories, and influencer partnerships. With Instagram’s robust features, we create campaigns designed to boost brand visibility, engagement, and conversions.

Our Instagram marketing services include managing posts, crafting impactful captions, creating Instagram ads, and running targeted campaigns to ensure that your brand reaches its ideal audience. We focus on building organic engagement through follower growth strategies, hashtags, and interactions with your community. Whether you want to showcase your products, share user-generated content, or run promotions, we’ll help you create a visually appealing, high-performing Instagram presence. With our expertise in Instagram ads and content strategies, we ensure your brand stands out in a competitive digital landscape.
            </p>
            <p>
              Leverage Instagram marketing services to elevate your brand:
            </p>
            <ul>
              <li>Custom strategies designed to meet your Instagram goals</li>
              <li>Comprehensive analytics to optimize performance</li>
              <li>Integration with existing marketing platforms</li>
              <li>Experienced team focused on social media success</li>
            </ul>
          </div>
          <div className="instagram-text-image-image">
            <img src={img1} alt="Instagram Marketing" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="instagram-marketing-page-why-choose">
          <h2 className="instagram-whychoose">Why Your Business Needs Instagram Marketing Services</h2>
          <div className="instagram-whychoose-grid">
            <div className="instagram-whychoose-item">
              <FaChartBar size={50} color="white" />
              <h3>Scalability</h3>
              <p>
                As your business grows, your Instagram marketing will grow with you. Our strategies scale accordingly.
              </p>
            </div>
            <div className="instagram-whychoose-item">
              <FaDatabase size={50} color="white" />
              <h3>Data-Driven</h3>
              <p>
                We track and analyze performance data to ensure each campaign is optimized for maximum effectiveness.
              </p>
            </div>
            <div className="instagram-whychoose-item">
              <FaGlobe size={50} color="white" />
              <h3>Global Reach</h3>
              <p>
                Expand your reach internationally with Instagram’s robust targeting capabilities.
              </p>
            </div>
            <div className="instagram-whychoose-item">
              <FaPalette size={50} color="white" />
              <h3>Brand Consistency</h3>
              <p>
                Maintain consistent brand aesthetics across all campaigns to build trust and recognition.
              </p>
            </div>
          </div>
        </section>

        {/* Instagram Marketing Services Section */}
        <section className="instagram-marketing-page-services">
          <h2 className="instagram-whychoose">Our Instagram Marketing Services</h2>
          <p>
            From strategy creation to in-depth campaign analysis, we offer everything you need to achieve your Instagram objectives, whether it's raising awareness, increasing consideration, or driving conversions.
          </p>
          <div className="instagram-services-grid">
            <div className="instagram-service-item">
              <h3>Analytics</h3>
              <p>
                We leverage data-tracking tools to gather insights that improve campaign performance.
              </p>
            </div>
            <div className="instagram-service-item">
              <h3>Audience Identification</h3>
              <p>
                We help identify your target audience and leverage Instagram’s targeting features effectively.
              </p>
            </div>
            <div className="instagram-service-item">
              <h3>Strategy</h3>
              <p>
                We craft and implement tailored Instagram strategies to meet your unique brand goals.
              </p>
            </div>
            <div className="instagram-service-item">
              <h3>Monitoring</h3>
              <p>
                We monitor your Instagram campaigns, addressing issues and seizing opportunities promptly.
              </p>
            </div>
            <div className="instagram-service-item">
              <h3>Paid Ads</h3>
              <p>
                We manage your paid ad campaigns, from ad creation to optimization and final analysis.
              </p>
            </div>
            <div className="instagram-service-item">
              <h3>Content Creation</h3>
              <p>
                We design and schedule regular posts, ensuring your content aligns with Instagram’s aesthetic appeal.
              </p>
            </div>
          </div>
        </section>

        {/* Instagram Marketing Team Section */}
        <section className="instagram-marketing-page-team">
          <h2 className="instagram-whychoose">Our Instagram Marketing Team</h2>
          <div className="instagram-team-grid">
            <div className="instagram-team-item">
              <h3>Transparent</h3>
              <p>
                We believe in transparent data analysis, sharing the insights that drive our tactics and learnings.
              </p>
            </div>
            <div className="instagram-team-item">
              <h3>Experienced</h3>
              <p>
                Our team brings years of experience in managing Instagram campaigns across various industries.
              </p>
            </div>
            <div className="instagram-team-item">
            <h3>Innovative</h3>
              <p>
              We use creative strategies and data-driven insights to craft unique Instagram campaigns, ensuring maximum engagement and brand growth.
              </p>
            </div>
          </div>
        </section>

      </div>
  
    </>
  );
};

export default InstagramMarketing;
