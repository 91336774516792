import React from "react";
import "./LeadGeneration.css";
import { FaBullseye, FaUserFriends, FaRegChartBar, FaCogs } from "react-icons/fa";
import img1 from "../../../Assest/Lead.png";

const LeadGenerationServices = () => {
  return (
    <>
      <div className="leadgeneration-page">
        {/* Banner Section */}
        <section className="leadgeneration-banner">
          <h1>Lead Generation Services</h1>
          <p>Unlock High-Quality Leads and Maximize Your Business Growth</p>
        </section>

        {/* Text and Image Section */}
        <section className="leadgeneration-text-image">
          <div className="leadgeneration-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Drive Your Sales with Effective Lead Generation
            </h2>
            <p>
              Our lead generation services help you attract and convert potential customers through proven strategies.<br></br> We focus on delivering high-quality leads tailored to your business needs.
            </p>
            <p>
              Benefits of using our lead generation services:
            </p>
            <ul>
              <li>Custom strategies that align with your target audience</li>
              <li>Enhanced conversion rates with qualified leads</li>
              <li>Streamlined marketing and sales funnel</li>
              <li>Detailed reporting and performance analysis</li>
            </ul>
          </div>
          <div className="leadgeneration-image">
            <img src={img1} alt="Lead Generation Services" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="leadgeneration-why-choose">
          <h2 className="leadgeneration-whychoose-title">
            Why Your Business Needs Lead Generation Services
          </h2>
          <div className="leadgeneration-whychoose-grid">
            <div className="leadgeneration-whychoose-item">
              <FaBullseye size={50} color="white" />
              <h3>Targeted Outreach</h3>
              <p>
                Reach the right audience with targeted strategies that ensure your message is delivered to those who matter most.
              </p>
            </div>
            <div className="leadgeneration-whychoose-item">
              <FaUserFriends size={50} color="white" />
              <h3>High-Quality Leads</h3>
              <p>
                We focus on generating leads that are more likely to convert, ensuring a higher ROI for your business.
              </p>
            </div>
            <div className="leadgeneration-whychoose-item">
              <FaRegChartBar size={50} color="white" />
              <h3>Scalable Strategies</h3>
              <p>
                Scale your lead generation efforts seamlessly as your business grows and evolves.
              </p>
            </div>
            <div className="leadgeneration-whychoose-item">
              <FaCogs size={50} color="white" />
              <h3>Automation</h3>
              <p>
                Leverage advanced tools and automation to streamline your lead generation and follow-up processes.
              </p>
            </div>
          </div>
        </section>

        {/* Lead Nurturing Section */}
        <section className="leadgeneration-nurturing">
          <h2 className="leadgeneration-nurturing-title">Lead Nurturing Process</h2>
          <div className="leadgeneration-nurturing-steps">
            <div className="leadgeneration-nurturing-step">
              <h3>Step 1: Identify Prospects</h3>
              <p>
                We start by identifying and targeting your ideal customers through data-driven insights and market research.
              </p>
            </div>
            <div className="leadgeneration-nurturing-step">
              <h3>Step 2: Capture Leads</h3>
              <p>
                Our strategies include creating engaging content, landing pages, and ads that effectively capture potential leads.
              </p>
            </div>
            <div className="leadgeneration-nurturing-step">
              <h3>Step 3: Qualify Leads</h3>
              <p>
                We evaluate and filter leads to ensure they meet your criteria, focusing on quality over quantity.
              </p>
            </div>
            <div className="leadgeneration-nurturing-step">
              <h3>Step 4: Convert Leads</h3>
              <p>
                Once leads are qualified, our team works with you to convert them into paying customers through tailored strategies.
              </p>
            </div>
          </div>
        </section>

        {/* Additional Lead Generation Section */}
        <section className="leadgeneration-additional-section">
          <h2>How Our Services Boost Your ROI</h2>
          <div className="leadgeneration-additional-content">
            <p>
            At Webixgo, we specialize in Lead Generation Services designed to help businesses attract and convert high-quality leads that drive growth. As a trusted lead generation company, we use a mix of proven strategies, including PPC, SEO, content marketing, and social media outreach, to ensure your business generates consistent, qualified leads. We focus on understanding your target audience and creating campaigns that resonate with them, driving interest and engagement.

Our lead generation strategies are tailored to your specific business goals. Whether you're in e-commerce, real estate, or any other industry, we use digital marketing tools to capture potential customers' attention and nurture them through the sales funnel. By optimizing landing pages, improving conversion rates, and leveraging data analytics, we ensure that each lead generation campaign delivers measurable results. Webixgo’s approach to lead generation focuses on driving quality traffic, converting visitors into leads, and providing ongoing optimization to maximize ROI.
            </p>
            <p>
              <strong>Key Features of Our Lead Generation Services:</strong>
            </p>
            <ul>
              <li>Multichannel lead generation campaigns</li>
              <li>Customized strategies for your business goals</li>
              <li>Advanced tools and analytics for performance tracking</li>
              <li>Ongoing support and optimization</li>
            </ul>
            <p>
              Let us help you build a lead generation system that delivers consistent results for your business.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default LeadGenerationServices;
