import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logoGif from "../../Assest/weblog.gif";
import img from "../../Assest/banner1.webp";
import {
  FaBullhorn,
  FaSearch,
  FaDraftingCompass,
  FaThumbsUp,
  FaInstagramSquare,
  FaCode,
  FaPaintBrush,
  FaCashRegister,
  FaTachometerAlt,
  FaRobot,
  FaMobileAlt,
  FaAndroid,
  FaShoppingBag,
  FaBook,
  FaPenAlt,
  FaEnvelopeOpenText,
  FaGoogleWallet,
  FaHammer,
  FaMailBulk,
  FaReact,
  FaLaptopCode,
  FaFacebookF,
  FaLinkedin,
} from "react-icons/fa";
import { FaApple } from "react-icons/fa6";

const Navbar = () => {
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const servicesRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
      setIsServicesOpen(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target)) {
        setIsServicesOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the main menu
    setIsServicesOpen(false); // Close the mega menu
  };

  const iconSize = 24;

  return (
    <>
      <nav className={`navbar ${isScrolled ? "scrolled" : "visible"}`}>
        <div className="navbar-container">
        <div className="logo">
  <Link to="/" onClick={handleLinkClick}>

    <img
      src={logoGif} // Replace 'logoGif' with the path to your GIF file (e.g., '../../Assest/logo.gif')
      alt="Webixgo Logo"
      className="logo-gif"
    />
  </Link>



</div>

          <div className="menu-icon" onClick={toggleMenu}>
            &#9776;
          </div>
          <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about-us"
                className="nav-link"
                onClick={handleLinkClick}
              >
                About Us
              </Link>
            </li>
            <li
              className="nav-item"
              ref={servicesRef}
              onMouseEnter={() => setIsServicesOpen(true)}
            >
              <Link to="#" className="nav-link">
                Services
              </Link>
              {isServicesOpen && (
                <div
                  className="mega-menu"
                  onMouseLeave={() => setIsServicesOpen(false)}
                >
                  <div className="mega-menu-left">
                    <img src={img} alt="Menu Illustration" />
                    <p>Explore our comprehensive range of services!</p>
                  </div>
                  <div className="mega-menu-right">
                    <div className="menu-column">
                      <h4>
                        <FaBullhorn size={iconSize} style={{ color: "#E63946" }} />
                        <Link
                          to="/digital-marketing"
                          onClick={handleLinkClick}
                        >
                          Digital Marketing
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <FaRobot
                            size={iconSize}
                            style={{ color: "#F4A261" }}
                          />
                          <Link
                            to="/strategy-development"
                            onClick={handleLinkClick}
                          >
                            Strategy Development
                          </Link>
                        </li>
                        <li>
                          <FaTachometerAlt
                            size={iconSize}
                            style={{ color: "#2A9D8F" }}
                          />
                          <Link
                            to="/franchise-marketing"
                            onClick={handleLinkClick}
                          >
                            Franchise Marketing
                          </Link>
                        </li>
                        <li>
                          <FaHammer
                            size={iconSize}
                            style={{ color: "#457B9D" }}
                          />
                          <Link
                            to="/enterprise-marketing"
                            onClick={handleLinkClick}
                          >
                            Enterprise Marketing
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaSearch size={iconSize} style={{ color: "#1D3557" }} />
                        <Link to="/seo" onClick={handleLinkClick}>
                          SEO
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <FaDraftingCompass
                            size={iconSize}
                            style={{ color: "#E63946" }}
                          />
                          <Link to="/local-seo" onClick={handleLinkClick}>
                            Local SEO
                          </Link>
                        </li>
                        <li>
                          <FaGoogleWallet
                            size={iconSize}
                            style={{ color: "#F4A261" }}
                          />
                          <Link to="/technical-seo" onClick={handleLinkClick}>
                            Technical SEO
                          </Link>
                        </li>
                        <li>
                          <FaCashRegister
                            size={iconSize}
                            style={{ color: "#457B9D" }}
                          />
                          <Link to="/enterprise-seo" onClick={handleLinkClick}>
                            Enterprise SEO
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaThumbsUp size={iconSize} style={{ color: "#4267B2" }} />
                        <Link to="/social-media-marketing" onClick={handleLinkClick}>
                          Social Media Marketing
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <FaFacebookF
                            size={iconSize}
                            style={{ color: "#4267B2" }}
                          />
                          <Link to="/facebook" onClick={handleLinkClick}>
                            Facebook
                          </Link>
                        </li>
                        <li>
                          <FaInstagramSquare
                            size={iconSize}
                            style={{ color: "#C13584" }}
                          />
                          <Link to="/instagram" onClick={handleLinkClick}>
                            Instagram
                          </Link>
                        </li>
                        <li>
                          <FaLinkedin
                            size={iconSize}
                            style={{ color: "#0A66C2" }}
                          />
                          <Link to="/linkdin" onClick={handleLinkClick}>
                            LinkedIn
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaPaintBrush size={iconSize} style={{ color: "#F4A261" }} />
                        <Link to="/web-design" onClick={handleLinkClick}>
                          Web Design
                        </Link>
                      </h4>
                      <ul>
                        <li>
                          <FaCode size={iconSize} style={{ color: "#457B9D" }} />
                          <Link to="/custom-design" onClick={handleLinkClick}>
                            Custom Design
                          </Link>
                        </li>
                        <li>
                          <FaLaptopCode
                            size={iconSize}
                            style={{ color: "#21759B" }}
                          />
                          <Link to="/wordpress-design" onClick={handleLinkClick}>
                            WordPress Design
                          </Link>
                        </li>
                        <li>
                          <FaReact size={iconSize} style={{ color: "#61DBFB" }} />
                          <Link to="/react-development" onClick={handleLinkClick}>
                            React JS Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaCashRegister size={iconSize} style={{ color: "#DB4437" }} />
                        <Link to="/ppc-advertising" onClick={handleLinkClick}>PPC Advertising</Link>
                      </h4>
                      <ul>
                        <li>
                          <FaGoogleWallet size={iconSize} style={{ color: "#DB4437" }} />
                          <Link to="/google-ads" onClick={handleLinkClick}>Google Ads</Link>
                        </li>
                        <li>
                          <FaTachometerAlt size={iconSize} style={{ color: "#2A9D8F" }} />
                          <Link to="/lead-generation-services" onClick={handleLinkClick}>Lead Generation Services</Link>
                        </li>
                        <li>
                          <FaHammer size={iconSize} style={{ color: "#457B9D" }} />
                          <Link to="/franchise-ppc-marketing" onClick={handleLinkClick}>Franchise PPC Marketing</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaMobileAlt size={iconSize} style={{ color: "#F4A261" }} />
                        <Link to="/app-development" onClick={handleLinkClick}>App Development</Link>
                      </h4>
                      <ul>
                        <li>
                          <FaAndroid size={iconSize} style={{ color: "#3DDC84" }} />
                          <Link to="/android-development" onClick={handleLinkClick}>Android Development</Link>
                        </li>
                        <li>
                          <FaApple size={iconSize} style={{ color: "#000000" }} />
                          <Link to="/ios-development" onClick={handleLinkClick}>iOS Development</Link>
                        </li>
                        <li>
                          <FaReact size={iconSize} style={{ color: "#61DBFB" }} />
                          <Link to="/react-native" onClick={handleLinkClick}>React Native</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaShoppingBag size={iconSize} style={{ color: "#FFA500" }} />
                        <Link to="/E-commerce" onClick={handleLinkClick}>E Commerce</Link>
                      </h4>
                      <ul>
                        <li>
                          <FaBook size={iconSize} style={{ color: "#FFA500" }} />
                          <Link to="/Ecommerce_Design" onClick={handleLinkClick}>E-commerce Design</Link>
                        </li>
                        <li>
                          <FaPenAlt size={iconSize} style={{ color: "#1D3557" }} />
                          <Link to="/Ecommerce_SEO" onClick={handleLinkClick}>E-commerce SEO</Link>
                        </li>
                        <li>
                          <FaEnvelopeOpenText size={iconSize} style={{ color: "#DB4437" }} />
                          <Link to="/Ecommerce_PPC" onClick={handleLinkClick}>E-commerce PPC</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h4>
                        <FaBook size={iconSize} style={{ color: "#6A5ACD" }} />
                        <Link to="/content-marketing" onClick={handleLinkClick}>Content Marketing</Link>
                      </h4>
                      <ul>
                        <li>
                          <FaPenAlt size={iconSize} style={{ color: "#6A5ACD" }} />
                          <Link to="/seo-content-writing" onClick={handleLinkClick}>SEO Content Writing</Link>
                        </li>
                        <li>
                          <FaMailBulk size={iconSize} style={{ color: "#457B9D" }} />
                          <Link to="/copy-write-services" onClick={handleLinkClick}>Copywriting Services</Link>
                        </li>
                        <li>
                          <FaEnvelopeOpenText size={iconSize} style={{ color: "#61DBFB" }} />
                          <Link to="/email-marketing" onClick={handleLinkClick}>Email Marketing</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className="nav-item">
              <Link
                to="/portfolio"
                className="nav-link"
                onClick={handleLinkClick}
              >
                Portfolio
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/price" className="nav-link" onClick={handleLinkClick}>
                Plans
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/blog" className="nav-link" onClick={handleLinkClick}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact-us"
                className="nav-link"
                onClick={handleLinkClick}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <Link
            to="/contact-us"
            className="contact-button"
            onClick={handleLinkClick}
          >
            Get Proposal
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
