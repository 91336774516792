import React from 'react';
import './AboutUs.css';
import aboutBanner from "../../Assest/about.jpeg";
import About1 from './About1';
import About2 from './About2';
import About3 from './About3';

const AboutUs = () => {
  return (
    <>

   
      <div className="about-us-container">
        {/* Banner Section */}
        <div className="about-banner">
          <img src={aboutBanner} alt="About Us Banner" className="banner-image" />
          <div className="banner-text">
            <h1>About Us</h1>
            <p>Innovating the Future, Together</p>
          </div>
        </div>
        <About1/>
        <About2/>
        <About3/>
        
      </div>

    </>
  );
};

export default AboutUs;
