// App.js
import React from "react";
import "./CustomDesign.css";
import webdesignImage from "../../../Assest/CWD.png"; // Make sure to add the image to your src folder or adjust the path
import webomage2 from "../../../Assest/webdesignimg.webp"; // Replace with actual path to the uploaded image


function CustomDesign() {

  return (
    <>

    <div className="CustomDesign-App">
      <header className="CustomDesign-banner">
        <h1>Custom Design Solutions</h1>
      </header>

      <main className="CustomDesign-content">
        {/* Section 1 */}
        <section className="CustomDesign-section">
          <h2>Why Your Business Needs A Custom Web Design</h2>
          <div className="CustomDesign-flex-container">
            <div className="CustomDesign-flex-item">
              <p>
              At Webixgo, we specialize in Custom Design solutions that are tailored to meet your business needs and brand identity. Whether you're looking for a custom website, logo, or digital assets, our expert designers work closely with you to craft unique, visually appealing designs that stand out in the crowded digital landscape. As a leading custom design company, we focus on creating designs that reflect your business’s personality while ensuring usability and responsiveness across devices.

Our custom design services are aimed at improving user experience (UX) and engagement. We ensure that every design element aligns with your brand values, helping you establish a strong visual presence. From color schemes to typography and layout, we focus on every detail to ensure your website or digital platform represents your business in the best light possible. Whether you're a startup or an established brand, our custom design approach will help you build a memorable, impactful presence that resonates with your audience.
              </p>
       
              <p>
                Don't let this be the case for your business. Ensure your target
                customers can find and navigate your website with ease. Invest in a
                responsive, well-designed, and informative website to improve your
                brand's profitability and success.
              </p>
            </div>
            <div className="CustomDesign-flex-item CustomDesign-illustration">
              <img src={webdesignImage} alt="Custom Web Design Illustration" />
            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section className="CustomDesign-section">
          <h2>Custom Fit Web Design vs. Website Templates: What’s Right for Your Business?</h2>
          <div className="CustomDesign-flex-container">
            <div className="CustomDesign-flex-item CustomDesign-illustration">
              <img src={webomage2} alt="Comparison Illustration" />
            </div>
            <div className="CustomDesign-flex-item">
              <p>
                A custom design website allows you to establish and express your brand
                through tailored page elements that align with your objectives. Custom
                web design cost is relatively higher than website templates, but they
                offer numerous benefits that give you a competitive edge. A custom
                WordPress website design is more search engine-friendly, customer-centric,
                unique, and scalable than built-in templates.
              </p>
              <p>
                By choosing custom web design packages, you build a website around your
                specific customer journey and brand messaging. On the other hand, website
                templates provide pre-made solutions that are affordable and quick to set
                up but may lack flexibility and uniqueness.
              </p>
            </div>
          </div>
        </section>

        {/* Section 3 */}
        <section className="CustomDesign-services">
          <h2>Our Custom Web Design Services</h2>
          <div className="CustomDesign-grid">
            <div className="CustomDesign-service-item">
              <h3><i className="fas fa-code"></i> WordPress Experts</h3>
              <p>
                Don't risk having a generic website. Work with our experienced WordPress
                specialists to create an elegant and unique site for your brand.
              </p>
            </div>
            <div className="CustomDesign-service-item">
              <h3><i className="fas fa-chart-bar"></i> Website Analysis</h3>
              <p>
                We collect and analyze your website data to create a results-driven
                custom design aimed to increase your organic traffic.
              </p>
            </div>
            <div className="CustomDesign-service-item">
              <h3><i className="fas fa-palette"></i> Logo Design</h3>
              <p>
                Build a unique brand identity with a personalized logo designed by our
                experts.
              </p>
            </div>
            <div className="CustomDesign-service-item">
              <h3><i className="fas fa-video"></i> Video Production</h3>
              <p>
                Create visually engaging and compelling videos to keep your audience
                interested.
              </p>
            </div>
            <div className="CustomDesign-service-item">
              <h3><i className="fas fa-pen-alt"></i> Content Writing</h3>
              <p>
                High-quality, SEO-optimized content that enhances your brand message and
                attracts your target audience.
              </p>
            </div>
            <div className="CustomDesign-service-item">
              <h3><i className="fas fa-shopping-cart"></i> eCommerce Web Design</h3>
              <p>
                Provide a better shopping experience with high-quality custom eCommerce
                solutions tailored to your business.
              </p>
            </div>
          </div>
        </section>

        {/* Section 4 */}
        <section className="CustomDesign-process">
          <h2>Custom WordPress Website Design Process</h2>
          <p>
            Attract High-Intent Customers With An Engaging, Clutter-Free Custom Design Website
          </p>
          <div className="CustomDesign-flex-container">
            <div className="CustomDesign-process-step">
              <h3><i className="fas fa-flag"></i> Kick Off</h3>
              <p>
                During our initial consultation, our project managers determine your target customers, marketing goals, needs and demands. In this stage, we set your expectations, answer your queries, explain the different stages of your custom website design creation and provide initial custom web design pricing.
              </p>
            </div>
            <div className="CustomDesign-process-step">
              <h3><i className="fas fa-lightbulb"></i> Strategy</h3>
              <p>
                Strategy development serves as the basis of our next course of action. During this phase, we analyze all the data from the kick off, create a proposal, develop proposed site mock-ups and meet with your project manager for approval.
              </p>
            </div>
            <div className="CustomDesign-process-step">
              <h3><i className="fas fa-cogs"></i> Development</h3>
              <p>
                This phase covers site development, content optimization and on-site SEO. We ensure all elements of your website, such as your logo design, color scheme and interactive site elements, reflect your branding.
              </p>
            </div>
            <div className="CustomDesign-process-step">
              <h3><i className="fas fa-rocket"></i> Launch</h3>
              <p>
                Finally, we transfer your website from a local server to the live server. To ensure you have a clear grasp of your site needs, we also provide client training where we teach you how to manage the back-end of your website.
              </p>
            </div>
          </div>
        </section>

      </main>
    </div>
 
    </>
  );
}

export default CustomDesign;
