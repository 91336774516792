import React from "react";
import "./LinkdinMarketing.css";
import { FaChartBar, FaDatabase, FaGlobe, FaPalette } from "react-icons/fa";
import img1 from "../../../Assest/linkdin.webp";


const LinkedInMarketing = () => {
  return (
    <>

      <div className="linkedin-marketing-page">
        {/* Banner Section */}
        <section className="linkedin-marketing-page-banner">
          <h1>LinkedIn Marketing</h1>
          <p>Leverage LinkedIn for Professional Growth and Network Expansion</p>
        </section>

        {/* Text and Image Section */}
        <section className="linkedin-marketing-page-text-image">
          <div className="text-image-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Connect and Engage with Top Professionals on LinkedIn
            </h2>
            <p>
            For B2B companies, LinkedIn Marketing is one of the most effective platforms to build professional relationships, generate leads, and position your brand as an industry leader. Webixgo, as an expert LinkedIn marketing company, helps businesses harness the power of LinkedIn to establish their presence, connect with potential clients, and drive growth.

Our LinkedIn marketing services include optimizing company profiles, creating and sharing thought leadership content, and running targeted LinkedIn ads to promote services or products to a specific audience. We also focus on building and nurturing professional connections, helping your brand gain credibility and increase its authority in your industry. By focusing on lead generation strategies, we can ensure your business taps into the potential of LinkedIn to acquire high-quality prospects, generate inbound inquiries, and boost conversions. With LinkedIn marketing, we help you stay ahead of competitors, establish valuable industry connections, and drive business growth.
            </p>
            <p>
              Maximize your LinkedIn marketing with:
            </p>
            <ul>
              <li>Targeted strategies tailored to your LinkedIn goals</li>
              <li>Data-driven insights and performance tracking</li>
              <li>Seamless integration with your LinkedIn professional network</li>
              <li>Experienced team focused on B2B and professional marketing</li>
            </ul>
          </div>
          <div className="text-image-image">
            <img src={img1} alt="LinkedIn Marketing" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="linkedin-marketing-page-why-choose">
          <h2 className="linkedin-whychoose">Why Your Business Needs LinkedIn Marketing Services</h2>
          <div className="linkdin-why-choose-grid">
            <div className="linkdin-why-choose-item">
              <FaChartBar size={50} color="#3681AB" />
              <h3>Scalability</h3>
              <p>
                As your business grows, so does your LinkedIn marketing potential. Our strategies scale with your growth.
              </p>
            </div>
            <div className="linkdin-why-choose-item">
              <FaDatabase size={50} color="#3681AB" />
              <h3>Data-Driven</h3>
              <p>
                We use data insights to continuously refine and optimize your LinkedIn campaigns for maximum ROI.
              </p>
            </div>
            <div className="linkdin-why-choose-item">
              <FaGlobe size={50} color="#3681AB" />
              <h3>Global Reach</h3>
              <p>
                Extend your professional network globally with LinkedIn’s advanced targeting tools and features.
              </p>
            </div>
            <div className="linkdin-why-choose-item">
              <FaPalette size={50} color="#3681AB" />
              <h3>Brand Consistency</h3>
              <p>
                Maintain a consistent and professional brand presence across all LinkedIn campaigns to foster trust.
              </p>
            </div>
          </div>
        </section>

        {/* Case Studies Section */}
        <section className="linkedin-marketing-page-case-studies">
          <h2 className="linkedin-whychoose">Our LinkedIn Marketing Case Studies</h2>
          <div className="case-studies-grid">
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="linkedin-marketing-page-cta">
          <h2>Boost Your Brand with LinkedIn Marketing</h2>
          <p>
            Partner with our expert LinkedIn marketing team to grow your professional network and generate qualified leads.
          </p>
    
        </section>

        {/* Our LinkedIn Marketing Services */}
        <section className="linkedin-marketing-page-services">
          <h2 className="linkedin-whychoose">Our LinkedIn Marketing Services</h2>
          <p>
            From strategy development to ongoing campaign analysis, we help you meet your LinkedIn goals, whether it’s generating leads, driving website traffic, or building brand awareness.
          </p>
          <div className="linkdin-services-grid">
            <div className="linkdin-service-item">
              <h3>Analytics</h3>
              <p>
                We use data tools to track campaign performance and identify areas for improvement, optimizing your LinkedIn strategy.
              </p>
            </div>
            <div className="linkdin-service-item">
              <h3>Audience Identification</h3>
              <p>
                We analyze your target audience to ensure your LinkedIn campaigns reach the right professionals.
              </p>
            </div>
            <div className="linkdin-service-item">
              <h3>Strategy</h3>
              <p>
                Our team will create a tailored LinkedIn marketing strategy that aligns with your B2B goals and objectives.
              </p>
            </div>
            <div className="linkdin-service-item">
              <h3>Monitoring</h3>
              <p>
                We monitor your LinkedIn page and campaigns, responding to feedback and optimizing performance.
              </p>
            </div>
            <div className="linkdin-service-item">
              <h3>Paid Ads</h3>
              <p>
                We manage LinkedIn paid campaigns from start to finish, ensuring each ad is optimized for success.
              </p>
            </div>
            <div className="linkdin-service-item">
              <h3>Content Creation</h3>
              <p>
                We help create quality, engaging content that resonates with your LinkedIn audience and strengthens your brand presence.
              </p>
            </div>
          </div>
        </section>

        {/* Our LinkedIn Marketing Team */}
       

        {/* Additional Information */}
        <section className="linkedin-marketing-page-additional">
          <h2>Why You Need a LinkedIn Marketing Team</h2>
          <p>
            LinkedIn is a platform designed for professionals and businesses. To succeed, you need content that engages users at a high standard. Our team can help you maintain that level of quality, visibility, and optimization.
          </p>
        </section>
      </div>

    </>
  );
};

export default LinkedInMarketing;
