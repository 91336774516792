import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Blog.css"; // Ensure to add styles for the page
import img1 from "../../Assest/NB logo.webp";
import img2 from "../../Assest/Overseas Logo.png";
import img3 from "../../Assest/Zig.jpeg";
import img4 from "../../Assest/Agni Logo.png";
import img5 from "../../Assest/medicoselogo.png";
import img6 from "../../Assest/aggarwal jewellers logo.png";
import img7 from "../../Assest/gymfit.webp";
import img8 from "../../Assest/Ikonic Logo.png";
import img9 from "../../Assest/pinaki.webp";
import img10 from "../../Assest/CKlogo.png";
import img11 from "../../Assest/CNlogo.png";
const BlogPage = () => {
  const [filter, setFilter] = useState("ALL");
  const navigate = useNavigate(); // Initialize navigate hook

  const projects = [
    { id: 1, name: "", category: "Website Project", description: "Developed a dynamic React.js website and enhanced its online presence with SEO, Social Media Marketing (SMM), and GMB optimization, driving visibility and engagement.", image: img1, technologies: "React.js | CSS | javaScript " },
    { id: 2, name: "", category: "Digital Marketing Project" , description: "Enhanced local presence with Social Media Marketing, GMB optimization, and Local SEO, boosting engagement, search rankings, and bookings.", image: img2, technologies: "SMM | Keywords | Backlinks" },
    { id: 3, name: "", category: "App Project", description: "Zigmate – A dynamic dating app designed to spark real connections with a seamless & engaging user experience!", image: img3, technologies: "React Native | Django" },
    { id: 4, name: "", category: "Digital Marketing Project", description: "Boosted Agni Roof Top’s social presence with engaging content & targeted campaigns, turning likes into loyal customers!", image: img4, technologies: "Social Media Platforms | Meta | AD Campiagn" },
    { id: 5, name: "", category: "App Project", description: "A one-stop medicine marketplace – making healthcare shopping easy, fast, and accessible with just a tap!", image: img5, technologies: "React Native | Node.js | CSS" },
    { id: 6, name: "", category: "Digital Marketing Project", description: "Transformed Aggarwal Jewellers' social media with stunning visuals & strategic campaigns, making their brand shine online!", image: img6, technologies: "Social Media Platforms | Meta | AD Campiagn" },
    { id: 7, name: "", category: "Website Project", description: "Crafted an engaging WordPress website for Action Perfection, ensuring a smooth, user-friendly experience for fitness lovers to explore and connect effortlessly.", image: img7, technologies: "WordPress" },
    { id: 8, name: "", category: "Digital Marketing Project", description: "Enhanced Ikonic Fitness' social media presence with engaging content and strategic campaigns, boosting brand visibility and customer engagement in the fitness industry.", image: img8, technologies: "Social Media Platforms | Meta | AD Campiagn" },
    { id: 9, name: "", category: "Digital Marketing Project", description: "Boosted Pinaki Bakery’s online presence with digital marketing strategies, including Social Media Marketing and targeted ads, driving higher engagement and customer reach.", image: img9, technologies: "Social Media Platforms | Meta | AD Campiagn" },
    { id: 10, name: "", category: "Digital Marketing Project", description: "Elevated digital presence with strategic Social Media Marketing, engaging content, and targeted campaigns, attracting more clients and boosting brand awareness.", image: img10, technologies: "Social Media Marketing | Meta Ads | Content Strategy" },
    { id: 11, name: "", category: "Digital Marketing Project", description: "Enhanced online visibility with Social Media Marketing, GMB optimization and Local SEO, driving customer engagement, improving local search rankings and increasing footfall.", image: img11, technologies: "Social Media Marketing | GMB | Local SEO" },
  ];

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredProjects = 
  filter === "ALL"
    ? projects
    : filter === "Social Media Project"
    ? projects.filter((project) => 
        project.category === "Social Media Project" || project.category === "SEO Project"
      )
    : projects.filter((project) => project.category === filter);


  const handleReadMoreClick = () => {
    navigate("/contact-us"); // Navigate to the Contact Us page
  };

  return (
    <>


      <div className="blog-page">
        {/* Banner Section */}
        <div className="banner-section">
          <h1>Our Success Stories</h1>
          <p>
            We have helped clients across the globe transform their tech development and digital marketing marketplaces for the future. Here are a few specimens of our exemplary case studies coming from clients ranging from startups to established enterprises.
          </p>
        </div>

        {/* Filter Section */}
        <div className="filter-section">
        
          <label htmlFor="category">Search by:</label>
          <select id="category" value={filter} onChange={handleFilterChange}>
            <option value="ALL">ALL</option>
            <option value="Website Project">Website Project</option>
            <option value="Digital Marketing Project">Digital Marketing Project</option>
            <option value="App Project">App Project</option>
          </select>
        </div>

        {/* Recent Work Section */}
        <div className="recent-work">
          <h2>Our Recent Work</h2>
          <div className="projects-grid">
            {filteredProjects.map((project) => (
              <div className="project-card" key={project.id}>
                <img src={project.image} alt={project.name} className="project-image" />
                <div className="card-content">
                  <h3>{project.name}</h3>
                  <p>{project.description}</p>
                  <p>
                    <strong>Technologies:</strong> {project.technologies}
                  </p>
                  <button
                    className="read-more"
                    onClick={handleReadMoreClick} // Add click handler for navigation
                  >
                    Contact Us
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
