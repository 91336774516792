import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import img from "../../Assest/logo.webp";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <div className="loan-banner-container">
        <div className="loan-banner-content">
          <div className="loan-banner-left">
            <h4 className="loan-heading">
              <span className="loan-line"></span>
              GET A QUICK Service
            </h4>
            <h1>Get A Quotation Quickly</h1>
          </div>
          <div className="loan-banner-right">
            <button
              className="apply-loan-btn"
              onClick={() => handleNavigation("/contact-us")}
            >
              Apply for Services &nbsp; →
            </button>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-main">
          <div className="footer-section">
            <img src={img} alt="Easilon Logo" className="footer-logo" />
            <p>
              Webixgo delivers innovative IT solutions, including web
              development, app creation, SEO, and digital marketing, to help
              your business grow.
            </p>
          </div>

          <div className="footer-section">
            <h5>Company & Products</h5>
            <ul>
              <li onClick={() => handleNavigation("/about-us")}>About Us</li>
              <li onClick={() => handleNavigation("/privacy-policy#privacy-policy")}>
                Privacy Policy
              </li>
              <li onClick={() => handleNavigation("/privacy-policy#terms-and-conditions")}>
                Terms & Conditions
              </li>
              <li onClick={() => handleNavigation("/contact-us")}>Contact Us</li>
            </ul>
          </div>

          <div className="footer-section">
            <h5>IT Services</h5>
            <ul>
              <li onClick={() => handleNavigation("/web-design")}>Web Development</li>
              <li onClick={() => handleNavigation("/app-development")}>App Development</li>
              <li onClick={() => handleNavigation("/seo")}>SEO</li>
              <li onClick={() => handleNavigation("/digital-marketing")}>Digital Marketing</li>
            </ul>
          </div>

          <div className="footer-section">
            <h5>Get In Touch</h5>
            <p>561, New Golden Avenue, Golden Avenue, Amritsar, Punjab 143001</p>
            <span className="contact-item">
                <i className="fas fa-envelope"></i>
                <a href="mailto:webixgo@gmail.com">webixgo@gmail.com</a>
              </span>
              <span className="contact-item">
                <i className="fas fa-phone-alt"></i>
                <a href="tel:+917347271629">+91-734-727-1629</a>
              </span>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61571715299914"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://x.com/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/webixgo/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/106135130/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://wa.me/917347271629"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>

          <p>© Copyright 2024 Webixgo — All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
