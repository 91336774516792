import React from "react";
import "./EnterpriseSEO.css";
import { FaChartBar, FaDatabase, FaGlobe, FaPalette } from "react-icons/fa";
import img1 from "../../../Assest/Enterprise3.png";
import img2 from "../../../Assest/Enterprise.jpeg";
import img3 from "../../../Assest/seoimg.webp";
import img4 from "../../../Assest/Enterprise2.jpeg";

const EnterpriseSEO = () => {
  return (
    <>

      <div className="enterprise-seo-page">
        {/* Banner Section */}
        <section className="enterprise-seo-page-banner">
          <h1>Enterprise SEO Solutions</h1>
          <p>Maximize Your Visibility. Achieve SEO Success at Scale.</p>
        
        </section>

        {/* Text and Image Section */}
        <section className="enterprise-seo-page-text-image">
          <div className="text-image-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Optimize and Scale Your SEO Strategy
            </h2>
            <p>
            Enterprise SEO involves optimizing large, complex websites with hundreds or thousands of pages to drive consistent and scalable traffic. At Webixgo, we specialize in Enterprise SEO, tailoring our approach to meet the unique needs of large organizations. Our team conducts in-depth audits of your website's structure and performance, addressing scalability challenges and ensuring optimal site architecture.

We work with your content strategy to ensure all pages are optimized for target keywords and aligned with your brand’s objectives. For large enterprises, managing SEO across multiple domains, subdomains, and regional variations can be challenging. Webixgo has the expertise to optimize each of these elements effectively, ensuring that your enterprise website ranks highly in search results across regions and markets.

We also use advanced tools and techniques to monitor performance, track SEO metrics, and implement continuous improvements to keep your site ranking at the top. With Enterprise SEO, we help your business grow its global presence, increase organic traffic, and streamline the management of SEO across complex websites. Our scalable solutions ensure that your enterprise can effectively manage SEO on a large scale while maintaining high performance and consistent results.
            </p>
            <p>
              Leverage enterprise SEO services to boost your online presence:
            </p>
            <ul>
              <li>Customized SEO strategies based on your business objectives</li>
              <li>Advanced analytics and performance tracking</li>
              <li>Seamless integration with your existing SEO platforms</li>
              <li>Experienced SEO team with expertise in digital transformation</li>
            </ul>
          </div>
          <div className="text-image-image">
            <img src={img1} alt="Enterprise SEO" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="enterprise-seo-page-why-choose">
          <h2>Why Your Business Needs Enterprise SEO</h2>
          <div className="why-choose-grid">
            <div className="why-choose-item">
              <FaChartBar size={50} color="white" />
              <h3>Scalability</h3>
              <p>
                As your business grows, so should your SEO strategy. Enterprise SEO ensures scalability and performance
                across various platforms.
              </p>
            </div>
            <div className="why-choose-item">
              <FaDatabase size={50} color="white" />
              <h3>Data-Driven</h3>
              <p>
                Leverage data and advanced analytics to make smarter SEO decisions. We provide tailored strategies
                based on actionable insights.
              </p>
            </div>
            <div className="why-choose-item">
              <FaGlobe size={50} color="white" />
              <h3>Global Reach</h3>
              <p>
                Expand globally with localized SEO strategies that work across multiple regions and markets.
              </p>
            </div>
            <div className="why-choose-item">
              <FaPalette size={50} color="white" />
              <h3>Brand Consistency</h3>
              <p>
                Ensure consistency across all SEO channels, enhancing brand recognition and trust in your market.
              </p>
            </div>
          </div>
        </section>

        {/* Case Studies Section */}
        <section className="enterprise-seo-page-case-studies">
          <h2>Enterprise SEO Success Stories</h2>
          <div className="case-studies-grid">
            <div className="case-study-card">
              <img src={img2} alt="Case Study 1" />
              <h3>Performance Growth</h3>
              <p>+260% Organic Traffic in 12 months</p>
            </div>
            <div className="case-study-card">
              <img src={img3} alt="Case Study 2" />
              <h3>E-Commerce Growth</h3>
              <p>+387% increase in organic sales</p>
            </div>
            <div className="case-study-card">
              <img src={img4} alt="Case Study 3" />
              <h3>App Store SEO</h3>
              <p>+164% app downloads from organic search</p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="enterprise-seo-page-cta">
          <h2>Take Your SEO to the Next Level</h2>
          <p>
            Work with experts who can help scale your SEO efforts and drive long-term results for your business.
          </p>
  
        </section>
      </div>

    </>
  );
};

export default EnterpriseSEO;
