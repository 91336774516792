import React, { useState } from "react";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";  // Import default styles
import "./ContactUs.css";
import img from "../../Assest/ConUS.png";


// Currency Options - 50 countries
const currencyOptions = [
  { value: "GBP", label: "£ GBP (British Pound)" }, // Default currency
  { value: "USD", label: "$ USD (US Dollar)" },
  { value: "EUR", label: "€ EUR (Euro)" },
  { value: "INR", label: "₹ INR (Indian Rupee)" },
  { value: "AUD", label: "A$ AUD (Australian Dollar)" },
  { value: "CAD", label: "C$ CAD (Canadian Dollar)" },
  { value: "JPY", label: "¥ JPY (Japanese Yen)" },
  { value: "CNY", label: "¥ CNY (Chinese Yuan)" },
  { value: "CHF", label: "₣ CHF (Swiss Franc)" },
  { value: "SGD", label: "S$ SGD (Singapore Dollar)" },
  { value: "NZD", label: "NZ$ NZD (New Zealand Dollar)" },
  { value: "HKD", label: "HK$ HKD (Hong Kong Dollar)" },
  { value: "KRW", label: "₩ KRW (South Korean Won)" },
  { value: "MXN", label: "$ MXN (Mexican Peso)" },
  { value: "BRL", label: "R$ BRL (Brazilian Real)" },
  { value: "RUB", label: "₽ RUB (Russian Ruble)" },
  { value: "ZAR", label: "R ZAR (South African Rand)" },
  { value: "AED", label: "د.إ AED (UAE Dirham)" },
  { value: "THB", label: "฿ THB (Thai Baht)" },
  { value: "MYR", label: "RM MYR (Malaysian Ringgit)" },
  { value: "PHP", label: "₱ PHP (Philippine Peso)" },
  { value: "IDR", label: "Rp IDR (Indonesian Rupiah)" },
  { value: "VND", label: "₫ VND (Vietnamese Dong)" },
  { value: "EGP", label: "£ EGP (Egyptian Pound)" },
  { value: "SAR", label: "﷼ SAR (Saudi Riyal)" },
  { value: "TRY", label: "₺ TRY (Turkish Lira)" },
  { value: "NOK", label: "kr NOK (Norwegian Krone)" },
  { value: "SEK", label: "kr SEK (Swedish Krona)" },
  { value: "DKK", label: "kr DKK (Danish Krone)" },
  { value: "PLN", label: "zł PLN (Polish Zloty)" },
  { value: "HUF", label: "Ft HUF (Hungarian Forint)" },
  { value: "CZK", label: "Kč CZK (Czech Koruna)" },
  { value: "BGN", label: "лв BGN (Bulgarian Lev)" },
  { value: "RON", label: "lei RON (Romanian Leu)" },
  { value: "ARS", label: "$ ARS (Argentine Peso)" },
  { value: "CLP", label: "$ CLP (Chilean Peso)" },
  { value: "COP", label: "$ COP (Colombian Peso)" },
  { value: "PEN", label: "S/. PEN (Peruvian Sol)" },
  { value: "ISK", label: "kr ISK (Icelandic Krona)" },
  { value: "LKR", label: "Rs LKR (Sri Lankan Rupee)" },
  { value: "NGN", label: "₦ NGN (Nigerian Naira)" },
  { value: "KWD", label: "د.ك KWD (Kuwaiti Dinar)" },
  { value: "BHD", label: "د.ب BHD (Bahraini Dinar)" },
  { value: "OMR", label: "﷼ OMR (Omani Rial)" },
];


const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    website: "",
    email: "",
    phone: "",
    businessDetails: "",
    referral: "",
    budget: "",
    currency: "GBP", // Default currency GBP
  });

  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData({ ...formData, email });

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailPattern)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(""); // Clear error if valid
    }
  };
  const handleCurrencyChange = (e) => {
    setFormData({ ...formData, currency: e.target.value });
  };

  const handleBudgetChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (value.length > 7) return; // Restrict to 7 digits
    setFormData({ ...formData, budget: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailError || !formData.email) {
      setEmailError("Please enter a valid email before submitting.");
      return;
    }
    emailjs
      .send(
        "service_jgf9i7f",
        "template_r215nvb",
        formData,
        "9MQVI6mkzUo_dTksJ"
      )
   
      .then(
        () => {
          setMessage("Your message has been sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            company: "",
            website: "",
            email: "",
            phone: "",
            businessDetails: "",
            referral: "",
            budget: "",
            currency: "GBP",
          });
          setEmailError(""); // Clear error after successful submit
        },
        () => {
          setMessage("There was an error sending your message. Please try again.");
        }
      );
  };

  return (
    <div className="contact-us-page">
      <div className="top-banner">
        <h1>Contact Us</h1>
        <p>Partner with us to scale your business and achieve real results.</p>
      </div>

      <div className="form-container">
        <div className="form-section">
          <h2>Experience Real Results</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="firstName"
                placeholder="First Name*"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
         
              />
            </div>
            <div className="form-row">
              <input
                type="text"
                name="company"
                placeholder="Company/Organization"
                value={formData.company}
                onChange={handleChange}
       
              />
              <input
                type="text"
                name="website"
                placeholder="Website"
                value={formData.website}
                onChange={handleChange}
              />
            </div>
            <div className="form-row">
            <input
  type="email"
  name="email"
  placeholder="Email Address*"
  value={formData.email}
  onChange={handleEmailChange}
  required
/>
{emailError && <p style={{ color: "red", fontSize: "12px" }}>{emailError}</p>}

              <PhoneInput
                country={"gb"} // Default country code
                value={formData.phone}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "phone",
                  required: true,
                  placeholder: "Phone Number*",
                }}
              />
            </div>
            <div className="form-row">
      {/* Currency Dropdown */}
      <select name="currency" value={formData.currency} onChange={handleCurrencyChange}>
        {currencyOptions.map((currency) => (
          <option key={currency.value} value={currency.value}>
            {currency.label}
          </option>
        ))}
      </select>

      {/* Budget Input Field */}
      <input
  type="text"
  name="budget"
  placeholder={formData.budget ? "" : "Enter your Budget*"}
  value={formData.budget ? `${currencyOptions.find(c => c.value === formData.currency)?.label.split(" ")[0]} ${formData.budget}` : ""}
  onChange={handleBudgetChange}
  required
/>
    </div>
            <div className="form-row">
  
                <textarea
              name="referral"
              placeholder="What service do you need?"
              value={formData.referral}
              onChange={handleChange}
            ></textarea>
            </div>
        
            <textarea
              name="businessDetails"
              placeholder="Tell us about your business !"
              value={formData.businessDetails}
              onChange={handleChange}
            ></textarea>
           
            <button type="submit">SUBMIT</button>
          </form>
          {message && <p className="contact-note">{message}</p>}
        </div>

        <div className="info-section">
          <h2>Ready to Dominate Your Market?</h2>
          <img src={img} className="small-image" alt="Help" />
          <ol>
            <li>
              <strong>Contact Us</strong>: Fill out our brief contact form, and
              we'll be in touch with you soon to learn more about your business.
            </li>
            <li>
              <strong>Launch Targeted Campaigns</strong>: Our digital marketing
              experts will work closely with you to implement data-driven
              strategies that drive business growth.
            </li>
            <li>
              <strong>Achieve Lasting Growth</strong>: Solidify your digital
              presence, expand your brand reach and see outstanding results
              month-over-month.
            </li>
          </ol>
          <div className="contact-details">
            <p>
              <strong>Headquarters:</strong> 561, New Golden Avenue, Golden Avenue, Amritsar, Punjab 143001
            </p>
            <p>
              <strong>Office Hours:</strong> Mon-Sat, 10:00 AM – 10:00 PM
            </p>
            <p>
              <strong>Main Phone:</strong> +91-7347271629
            </p>
          </div>
        </div>
      </div>

      <div className="map-section">
        <h3>Our Location</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3424.188706399837!2d74.86457767607286!3d31.63189438131671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39196320b4c6f11d%3A0x61fb788b82878415!2sAmritsar%2C%20Punjab%20143006%2C%20India!5e0!3m2!1sen!2sin!4v1687288003002!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Our Location"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
