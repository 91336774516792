import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './BlogsPage.css';
import Blog1 from '../../Assest/Blog1.png';
import Blog2 from '../../Assest/blog2.png';

const BlogsPage = () => {
  // List of available paths
  const validPaths = [
    '/digital-marketing-company-India',
    '/digital-marketing',
    '/why-digital-marketing',
    '/reach-target-audience',
    '/build-brand-awareness',
    '/boost-revenue',
    '/stay-competitive',
    '/services',
    '/seo',
    '/social-media-marketing  ',
    '/ppc-advertising',
    '/gmb',
    '/videography',
    '/graphic-design',
    '/website-design',
    '/content-marketing',
    '/benefits',
    '/expertise',
    '/cost-effective',
    '/time-saving',
    '/custom-strategies',
    '/about-us',
    '/netbond-technologies',
  ];

  // Function to render a link if the path is valid
  const renderLink = (path, text) =>
    validPaths.includes(path) ? <Link to={path}>{text}</Link> : <span>{text}</span>;

  return (
    <div className="vlog-container">
      <h1 className="vlog-title">
        The Ultimate Guide to Choosing the Best<br></br> Digital Marketing Company in India
      </h1>

      <div className="vlog-section">
        <div className="vlog-content">
          <p>
            In today&#39;s fast-paced digital world, having a strong online presence is crucial for any business to thrive. If you&#39;re a business owner in India looking to scale your operations and attract more customers, partnering with a professional{' '}
            {renderLink('/digital-marketing', 'digital marketing company')} can be your ultimate game-changer.
          </p>
        </div>
        <img src={Blog1} alt="Digital Marketing" className="vlog-image" />
      </div>

      <div className="vlog-section reverse">
        <img src={Blog2} alt="Digital Marketing Services" className="vlog-image" />
        <div className="vlog-content">
          <h2>Why Digital Marketing is Essential for Businesses in India? </h2>
          <p>
            It is not just a cultural hub but also a thriving business center. With increasing competition in almost every industry, standing out in the local and global market has become more challenging than ever. Digital marketing provides businesses with the tools and strategies to:
          </p>
          <ul className="vlog-list">
  <li>
    <b>Reach Target Audiences:</b> Connect with potential customers who are actively searching for your products or services.
  </li>
  <li>
    <b>Build Brand Awareness:</b> Establish your brand as a leader in your niche.
  </li>
  <li>
    <b>Boost Revenue:</b> Drive more traffic to your website and convert leads into paying customers.
  </li>
  <li>
    <b>Stay Competitive:</b> Keep up with competitors who are already leveraging digital marketing.
  </li>
</ul>
        </div>
      </div>

      <div className="vlog-section">
        <div className="vlog-content">
          <h2>Services Offered by a Leading Digital Marketing Company in India</h2>
          <ol>
            <li>
              {renderLink('/seo', 'Search Engine Optimization (SEO)')}: SEO is the backbone of digital marketing. Key aspects of SEO include:
              <ul>
                <li>Keyword Research</li>
                <li>On-page Optimization</li>
                <li>Off-page SEO</li>
                <li>Local SEO for businesses in India</li>
              </ul>
            </li>
            <li>
              {renderLink('/social-media-marketing', 'Social Media Marketing (SMM)')}: Social media platforms like Facebook, Instagram, and LinkedIn are powerful tools for engaging with your audience.
              <ul>
                <li>Creating compelling content</li>
                <li>Running ad campaigns</li>
                <li>Engaging with followers</li>
                <li>Analyzing performance metrics</li>
              </ul>
            </li>
            <li>
              {renderLink('/ppc-advertising', 'Pay-Per-Click Advertising (PPC)')}: PPC campaigns are ideal for businesses that want immediate visibility.
              <ul>
                <li>Develop effective ad copy</li>
                <li>Optimize bidding strategies</li>
                <li>Track campaign performance</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <div className="vlog-section reverse">
        <div className="vlog-content">
          <h2>Benefits of Hiring a Digital Marketing Company in India</h2>
          <ul>
            <li><b>Expertise :</b> Gain access to a team of professionals skilled in various aspects of digital marketing.</li>
            <li><b>Cost-Effective :</b> Outsourcing your marketing efforts can be more affordable than hiring an in-house team.</li>
            <li><b>Time-Saving :</b> Focus on your core business activities while the agency handles your marketing.</li>
            <li><b>Customized Strategies :</b> Receive tailored marketing plans designed specifically for your business goals.</li>
          </ul>
        </div>
      </div>

      <h2 className="vlog-subtitle">
        Why {renderLink('/about-us', 'Choose Us')}?
      </h2>
      <div className="vlog-section">
        <div className="vlog-content">
          <p>
            At {renderLink('/Webixgo', 'Webixgo')}, we pride ourselves on being a trusted{' '}
            Digital Marketing Company in India that delivers results. Our team of experts specializes in creating customized marketing strategies to help businesses achieve their goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogsPage;
