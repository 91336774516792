import React from 'react';
import './About1.css';
import { MdStar, MdStars, MdTrendingUp } from 'react-icons/md'; // Material Icons for reviews
import badge from '../../Assest/AbtUS.png'; // Replace with actual image path
import videoThumbnail from '../../Assest/execution.webp'; // Replace with actual image path

const About1 = () => {
  return (
    <div className="section-container">
      {/* Review Section */}
      {/* <div className="review-section">
        <div className="review">
          <MdStar className="review-icon" />
          <p>⭐⭐⭐⭐⭐</p>
          <p>150+ Reviews</p>
        </div>
        <div className="review">
          <MdStars className="review-icon" />
          <p>⭐⭐⭐⭐⭐</p>
          <p>50+ Reviews</p>
        </div>
        <div className="review">
          <MdTrendingUp className="review-icon" />
          <p>⭐⭐⭐⭐⭐</p>
          <p>50+ Reviews</p>
        </div>
      </div> */}

      {/* About Section */}
      <div className="about-section">
        <div className="about-text">
          <h2>We’re All About Relationships and Results</h2>
          <p>
          Webixgo boasts a unique, close-knit team with experts in all facets of digital marketing, including strategic, creative, and technical domains. As a recognized leader in digital marketing services, Webixgo has more than 220 employees across 34 states and is located in 10 different countries. We work closely together to ensure smooth campaign development and execution for all our clients.
          </p>
          <p>
            What started as a one-person shop has grown into a full-service
            digital agency that serves thousands of clients worldwide. We work
            with businesses of all sizes, from startups to large franchises, in
            most industries and niches.
          </p>
          <p>
            As we grow, we continue to improve and invest in state-of-the-art
            tools and resources to provide top-notch solutions.
          </p>
        </div>
        <div className="about-media">
   
          <div className="video-container">
            <img
              src={badge}
              alt="Video Thumbnail"
              className="video-thumbnail"
            />
       
          </div>
        </div>
      </div>
    </div>
  );
};

export default About1;
