import React from 'react';
import { Link } from 'react-router-dom';
import './About3.css';
import { IconContext } from 'react-icons';
import { FaBullhorn, FaSearch, FaMousePointer, FaGlobe, FaThumbsUp, FaStar, FaShoppingCart, FaEnvelope } from 'react-icons/fa';

const services = [
  { path: "/digital-marketing", icon: <FaBullhorn />, title: "Digital Marketing", description: "Gain complete control of your digital real estate with holistic online marketing services tailored to your brand." },
  { path: "/seo", icon: <FaSearch />, title: "Search Engine Optimization (SEO)", description: "Get white-hat SEO solutions that improve your organic search rankings and increase brand recognition." },
  { path: "/ppc-advertising", icon: <FaMousePointer />, title: "Pay-Per-Click (PPC) Marketing", description: "Position your brand in front of the right people at the right time with a targeted paid ad campaign." },
  { path: "/web-design", icon: <FaGlobe />, title: "Web Design and Development", description: "Improve your website accessibility and attract lifetime clients to your business." },
  { path: "/social-media-marketing", icon: <FaThumbsUp />, title: "Social Media Marketing", description: "Bring your brand closer to your target audience and gain better customer engagement with social media marketing." },
  { path: "/lead-generation-services", icon: <FaStar />, title: "Online Reputation Management", description: "Build and maintain your desired brand image to attract more business." },
  { path: "/e-commerce", icon: <FaShoppingCart />, title: "eCommerce Marketing", description: "Facilitate a smooth buying process for your customers and reduce operational costs with strategic eCommerce marketing solutions." },
  { path: "/email-marketing", icon: <FaEnvelope />, title: "Email Marketing", description: "Boost engagement and sales with our personalized email campaigns." },
];

const About3 = () => {
  return (
    <div className="services-container">
      <h2>We’re a Results-Driven Digital Marketing Agency</h2>
      <p>
        At Webixgo, we take a strategy-first approach to show you real results. Our digital marketing team goes above and beyond to ensure our web marketing campaigns not only drum up new business for our clients but also support sustainable success.
      </p>
      <IconContext.Provider value={{ color: 'white', size: '2em' }}>
        <div className="services-grid">
          {services.map((service, index) => (
            <Link to={service.path} key={index} className="service-item">
              <div className="service-icon-container">
                {service.icon}
              </div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </Link>
          ))}
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default About3;
