import React, { useEffect, useState } from 'react';
import './Navbar2.css'; // Import the CSS file for styles
import Navbar from '../Navbar/Navbar';

function Navbar2() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="navbar2">
        {/* Left Section: Contact Info */}
        <div className="contact-info">
          {isMobile ? (
            <>
              <span className="contact-item">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="contact-item">
                <i className="fas fa-phone-alt"></i>
              </span>
            </>
          ) : (
            <>
              <span className="contact-item">
                <i className="fas fa-envelope"></i>
                <a href="mailto:webixgo@gmail.com">webixgo@gmail.com</a>
              </span>
              <span className="contact-item">
                <i className="fas fa-phone-alt"></i>
                <a href="tel:+917347271629">+91-734-727-1629</a>
              </span>
            </>
          )}
        </div>

        {/* Right Section: Social Icons */}
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61571715299914" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://x.com/home" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/webixgo/?hl=en" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/106135130/admin/dashboard/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a
              href="https://wa.me/917347271629"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
        </div>
      </div>
    </>
  );
}

export default Navbar2;
