import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Serviceshome.css";
import img1 from "../../Assest/wevdev.webp";
import img2 from "../../Assest/SEO.webp";
import img3 from "../../Assest/UIUX.webp";
import img4 from "../../Assest/mobileapp.webp";
import img5 from "../../Assest/socialmedia.webp";
import img6 from "../../Assest/digitalmar.webp";

// Service data
const services = [
  {
    id: 1,
    image: img1,
    icon: "👨‍💻",
    title: "Web Development",
    description: "Build modern, responsive websites tailored to your business needs.",
    path: "/web-design",
  },
  {
    id: 2,
    image: img2,
    icon: "📈",
    title: "SEO Optimization",
    description: "Optimize your website to rank higher on search engines and attract more traffic.",
    path: "/seo",
  },
  {
    id: 3,
    image: img3,
    icon: "💻",
    title: "UI/UX Design",
    description: "Create user-friendly designs that enhance the customer experience.",
    path: "/custom-design",
  },
  {
    id: 4,
    image: img4,
    icon: "📱",
    title: "Mobile App Development",
    description: "Develop cutting-edge mobile apps for iOS and Android platforms.",
    path: "/app-development",
  },
  {
    id: 5,
    image: img5,
    icon: "🌐",
    title: "Social Media",
    description: "Stay updated and engaged—follow us on social media for the latest updates, news, and more!",
    path: "/social-media-marketing",
  },
  {
    id: 6,
    image: img6,
    icon: "💡",
    title: "Digital Marketing",
    description: "Empower your brand with our expert digital marketing solutions.",
    path: "/digital-marketing",
  },
];

const Serviceshome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate

  const cardsPerView = 3; // Number of cards visible at a time

  const nextSlide = () => {
    if (currentIndex < services.length - cardsPerView) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleCardClick = (path) => {
    navigate(path); // Navigate to the specified route
  };

  return (
    <section className="ourservices-container">
      <h1 className="ourservices-title">Our Services</h1>
      <p className="ourservices-description">
        {/* Add your description here */}
      </p>
      <div className="ourservices-carousel">
        <button className="ourservices-arrow left-arrow" onClick={prevSlide}>
          ❮
        </button>
        <div className="ourservices-grid">
          {services.slice(currentIndex, currentIndex + cardsPerView).map((service) => (
            <div
              className="ourservices-card"
              key={service.id}
              onClick={() => handleCardClick(service.path)} // Attach onClick handler
            >
              <img
                src={service.image}
                alt={service.title}
                className="ourservices-card-image"
              />
              <div className="ourservices-card-content">
                <div className="ourservices-card-icon">{service.icon}</div>
                <h2 className="ourservices-card-title">{service.title}</h2>
                <p className="ourservices-card-description">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
        <button className="ourservices-arrow right-arrow" onClick={nextSlide}>
          ❯
        </button>
      </div>
    </section>
  );
};

export default Serviceshome;
